<template>
    <div>
        <div class="header">Groups</div>
        <router-link :to="{name: 'Group', params: {id: group.id}}" v-for="group in $store.state.groups" :key="group.id" class="link">
            {{ group.name }} <span v-if="group.count" class="count">{{ group.count }}</span>
        </router-link>
        <button @click="collapsed = !collapsed" class="btn-link">Add group</button>
        <Collapse :collapsed="!error.active" @end-close="error.message = ''">
            <div class="error">{{ error.message }}</div>
        </Collapse>
        <Collapse :collapsed="collapsed" @end-open="focus">
            <form @submit.prevent="addGroup" class="add-group" autocomplete="off">
                <label class="label">Group name</label>
                <input name="name" type="text" v-model="name" required class="input">
                <label class="label">Passphrase</label>
                <input name="password" type="password" v-model="passphrase" required class="input">
                <input type="submit" value="Add" class="btn-submit">
                <div class="help">Group names can be whatever you want and aren't visible to anyone but you. Remember the passphrase, you won't be able to look it up later (although you will be able to change it)</div>
            </form>
        </Collapse>
    </div>
</template>

<script>
import Collapse from '@/components/Collapse'

export default {
    name: 'GroupList',
    data() {
        return {
            name: '',
            passphrase: '',
            collapsed: true,
            error: {
                active: false,
                message: ''
            }
        }
    },
    components: {
        Collapse
    },
    methods: {
        addGroup() {
            this.axios.post('admin/group/add/', {name: this.name, passphrase: this.passphrase})
            .then(resp => {
                this.name = ''
                this.passphrase = ''
                this.$store.commit('addGroup', resp.data.group)
                this.collapsed = true
            })
            .catch(err => this.notifyError(err.response.data.message))
        },
        focus() {
            this.$el.querySelector('input[name="name"]').focus()
        },
        notifyError(message) {
            this.error.message = message
            this.$nextTick(() => this.error.active = true)
            setTimeout(() => {
                this.error.active = false
            }, 5000)
        }
    }
}
</script>

<style scoped>
.header {
    padding: .5rem 1rem;
    font-weight: bold;
    color: black;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.link {
    display: block;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    text-decoration: none;
    border-radius: .5rem;
    transition: background-color .3s;
    color: black;
    position: relative;
    border: 1px solid transparent;
}
.link:hover:not(.router-link-exact-active) {
    background-color: #ddd;
}
.router-link-exact-active {
    background-color: #cfffe5;
    border: 1px solid #98ff98;
}
.count {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    padding: .25rem .5rem;
}
.add-group {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: .25rem .5rem;
    border: 1px solid #ddd;
    border-radius: .5rem;
}
.btn-link {
    background: none;
    border: none;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    color: blue;
    cursor: pointer;
    font-size: 1rem;
}
.btn-submit {
    cursor: pointer;
}
.label {
    font-size: .75rem;
    color: black;
}
.input {
    flex: 1 1 auto;
    display: block;
    padding: .25rem .5rem;
    border: 1px solid gray;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    margin-bottom: .5rem;
}
.help {
    font-size: .75rem;
    font-style: italic;
    margin-top: 1rem;
}
.error {
    background-color: pink;
    border: 1px solid red;
    font-size: .75rem;
    padding: 1rem;
    margin: .25rem .5rem;
    border-radius: .5rem;
}
</style>