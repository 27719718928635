<template>
    <div class="admin">
        <div class="sidebar" :class="{expanded: $store.state.sidebar.expanded}">
            <div class="links">
                <router-link :to="{name: 'Upload'}" class="link">Photos</router-link>
                <router-link :to="{name: 'Logs'}" class="link">Logs</router-link>
            </div>
            <GroupList class="groups"></GroupList>
            <LabelList class="labels"></LabelList>
        </div>
        <router-view class="content"></router-view>
    </div>
</template>

<script>
import GroupList from '@/components/GroupList'
import LabelList from '@/components/LabelList'

export default {
    name: 'Admin',
    components: {
        GroupList,
        LabelList
    },
    created() {
        this.axios.get('admin/group/list/')
        .then(resp => this.$store.commit('setGroups', resp.data.groups))
        .catch(() => {})
        
        this.axios.get('admin/label/list/')
        .then(resp => this.$store.commit('setLabels', resp.data.labels))
        .catch(() => {})
    },
    beforeRouteUpdate(to, from, next) {
        this.$store.commit('closeSidebar')
        next()
    }
}
</script>

<style scoped>
.sidebar {
    position: fixed;
    width: 300px;
    top: 3.5rem;
    left: 0;
    bottom: 0;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    overflow: auto;
}
.content {
    margin-left: 300px;
}
.link {
    display: block;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    text-decoration: none;
    border-radius: .5rem;
    transition: background-color .3s;
    color: black;
    border: 1px solid transparent;
}
.groups,
.labels {
    margin-top: 2rem;
}
.link:hover:not(.router-link-exact-active) {
    background-color: #ddd;
}
.router-link-exact-active {
    background-color: #cfffe5;
    border: 1px solid #98ff98;
}
@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        z-index: 3;
        margin-left: -100%;
        transition: margin-left .3s;
    }
    .sidebar.expanded {
        margin-left: 0;
    }
    .content {
        margin-left: 0;
    }
}
</style>