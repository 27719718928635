<template>
    <div>
        <div class="header">Labels</div>
        <draggable v-model="labels" @change="updatePosition">
            <a v-for="label in $store.state.labels" :key="label.id" @click.prevent="$store.commit('filterLabel', label.id)" class="link" :class="{active: $store.state.filterLabel == label.id}">
                {{ label.name }} <span v-if="label.count" class="count">{{ label.count }}</span>
                <button v-if="false" @click.stop.prevent="deleteLabel(label)" class="btn-delete">
                    <FontAwesomeIcon icon="trash-alt"/>
                </button>
            </a>
        </draggable>
        <button @click="collapsed = !collapsed" class="btn-link">Add label</button>
        <Collapse :collapsed="!error.active" @end-close="error.message = ''">
            <div class="error">{{ error.message }}</div>
        </Collapse>
        <Collapse :collapsed="collapsed" @end-open="focus">
            <form @submit.prevent="addLabel" class="add-label" autocomplete="off">
                <label class="label">Label name</label>
                <input name="name" type="text" v-model="name" required class="input">
                <input type="submit" value="Add" class="btn-submit">
                <div class="help">Labels are used to categorize your photos. Examples include 'Reception', 'Ceremony', etc.</div>
            </form>
        </Collapse>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Collapse from '@/components/Collapse'

export default {
    name: 'LabelList',
    data() {
        return {
            name: '',
            collapsed: true,
            error: {
                active: false,
                message: ''
            }
        }
    },
    computed: {
        labels: {
            get() {
                return this.$store.state.labels
            },
            set(value) {
                return this.$store.commit('setLabels', value)
            }
        }
    },
    components: {
        Collapse,
        FontAwesomeIcon,
        draggable
    },
    methods: {
        addLabel() {
            this.axios.post('admin/label/add/', {name: this.name})
            .then(resp => {
                this.name = ''
                this.$store.commit('addLabel', resp.data.label)
                this.collapsed = true
            })
            .catch(err => this.notifyError(err.response.data.message))
        },
        deleteLabel(label) {
            this.axios.post('admin/label/delete/', {id: label.id})
            .then(() => this.$store.commit('removeLabel', label))
            .catch(() => {})
        },
        updatePosition(e) {
            const update = {
                label: e.moved.element.id,
                to: e.moved.newIndex,
                from: e.moved.oldIndex
            }
            this.axios.post('admin/label/move/', update)
            .catch(() => {})
        },
        focus() {
            this.$el.querySelector('input[name="name"]').focus()
        },
        notifyError(message) {
            this.error.message = message
            this.$nextTick(() => this.error.active = true)
            setTimeout(() => {
                this.error.active = false
            }, 5000)
        }
    }
}
</script>

<style scoped>
.header {
    padding: .5rem 1rem;
    font-weight: bold;
    color: black;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.link {
    display: block;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    text-decoration: none;
    border-radius: .5rem;
    transition: background-color .3s;
    color: black;
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
}
.link:hover:not(.active) {
    background-color: #ddd;
}
.link.active {
    background-color: #cfffe5;
    border: 1px solid #98ff98;
}
.count {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    padding: .25rem .5rem;
}
.add-label {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: .25rem .5rem;
    border: 1px solid #ddd;
    border-radius: .5rem;
}
.btn-delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0!important;
    color: #ccc;
    background: none;
    border: none;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    cursor: pointer;
    font-size: 1rem;
    transition: color .3s;
}
.btn-delete:hover {
    color: gray;
}
.btn-link {
    background: none;
    border: none;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    color: blue;
    cursor: pointer;
    font-size: 1rem;
}
.btn-submit {
    cursor: pointer;
}
.label {
    font-size: .75rem;
    color: black;
}
.input {
    flex: 1 1 auto;
    display: block;
    padding: .25rem .5rem;
    border: 1px solid gray;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    margin-bottom: .5rem;
}
.help {
    font-size: .75rem;
    font-style: italic;
    margin-top: 1rem;
}
.error {
    background-color: pink;
    border: 1px solid red;
    font-size: .75rem;
    padding: 1rem;
    margin: .25rem .5rem;
    border-radius: .5rem;
}
</style>